.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.landing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #212121;
}

.background-img {
  background-image: url("./assets/alt_landing.jpg");
  background-size: cover;
  background-position-x: right;
  background-position-y: 10vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  position: absolute;
}

.landing-text {
  padding: 2vh;
  position: relative;
  align-self: center;
  width: fit-content;
}

a {
  text-decoration: none;
  color: black;
}

.nav-container {
  display: flex;
  width: 100vw;
  height: 10vh;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  border-bottom: .25vh solid #DFDCE3;
}

.nav-btn {
  font-size: 1rem;
  text-decoration: none;
  padding: 1% 2%;
  border-radius: 5%;
  background-color: #DFDCE3;
}

.footer-container {
  display: flex;
  width: 100vw;
  height: 10vh;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  flex-wrap: wrap;
  color: black;
  background-color: #93C178;
  border-top: .25vh solid #DFDCE3;
}

.section-container {
  padding-top: 10vh;
  background: inherit;
}

.section-title {
  padding: 0.25em;
  margin-block-start: 0;
}

.about-content-container {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: 3% 0;
  flex-direction: column;
}

.about-content {
  margin: 0 3vw;
  text-align: justify;
  max-width: 570px;
}

.contact-container {
  height: 80vh;
  padding-top: 10vh;
  background: inherit;

}

.contact-links-container {
  height: 60vh;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.project-container {
  width: 90%;
  margin: 3% auto 0 auto;
  border: .2vw solid;
  border-color: #DFDCE3;
}

.project-title {
  background: #ECE6E1;
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0;
}

.info-container {
  display: flex;
  overflow: hidden;
}

.project-overview {
  width: 60%;
}

.project-info {
  width: 40%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.project-description {
  width: 80%;
  margin: 2vw auto;
  text-align: justify;
}

.tech-list {
  width: 85%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.tech-title {
  margin-bottom: 1vh;
  width: 80%;
}

.tech-item {
  min-width: 45%;
  text-align: start;
  margin: 1% 2%;
}

.project-link-container {
  display: flex;
  flex-flow: wrap-reverse;
  justify-content: space-around;
  align-content: space-around;
  width: 80%;
}

.project-link {
  height: 5vh;
  font-size: 1rem;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  margin: 1vh;
  padding: 1vh;
}

.project-img {
  height: 20vw;
  margin-top: 2vw;
}

.contact-btn {
  height: 10vh;
  padding: 2vw 5vw;
  font-size: 200%;
  border: .2vw solid;
  border-color: #DFDCE3;
  display: flex;
  justify-content: space-around; 
  align-items: center;
}

.media-logo {
  height: 80%;
  margin: 0 1.5vw 0 .5vw;
}

.email-logo {
  height: 120%;
  margin: 0 1.5vw 0 0;
}

.copyright-logo {
  height: 25%;
  margin-right: 1vw;
}

.email {
  height: 10vh;
  padding: 2vw 5vw;
  font-size: 1.25rem;
  border: .2vw solid;
  border-color: #DFDCE3;
  display: flex;
  justify-content: space-around; 
  align-items: center;
  padding: 0 2vh;
}


.video-container {
  width: 80%;
  margin: 0 auto 6% auto;
  position: relative;
  padding-bottom: 50%;
  padding-top: 0%;
  height: 0;
  overflow: visible;
}


.video-container iframe {
  position: absolute;
  margin-top: 2vw;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
  

/* Media Queries */

@media only screen 
  and (max-device-width: 550px) {
    .about-content-container {
      width: 90%;
    }

    .info-container {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }

    .project-overview {
      flex-flow: column;
      width: 100%;
      margin: 0 auto;
    }

    .project-info {
      width: 100%;
      height: inherit;
      padding: 0;
      justify-content: center;
    }

    .project-img {
      height: 20vh;
      margin-top: 2vh;
      justify-self: center;
    }
}

@media only screen 
  and (min-device-height: 1000px) {
    .project-link {
      max-height: 5vh;
    }

    .project-img {
      height: 20vh;
      margin-top: 2vw;
    }

    .project-title {
      font-size: 2rem;
    }

    .tech-title {
      font-size: 1.5rem;
    }

    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.5rem;
    }
}

/* Palette generated by Material Palette - materialpalette.com/light-green/deep-orange */

/* .dark-primary-color    { background: #465C8B; } */
/* .default-primary-color { background: rgba(147, 193, 120, .85); } */
/* .light-primary-color   { background: #DCEDC8; } */
/* .text-primary-color    { color: #212121; } */
.accent-color          { background: #F7EF6A; }
/* .primary-text-color    { color: #212121; } */
/* .secondary-text-color  { color: #757575; } */
.divider-color         { border-color: #DFDCE3; }

.dark-gray { background-color: rgba(179, 180, 177, 1); }
.gainsboro { background-color: rgba(236, 230, 225, .8); }
.default-primary-color { background-color: rgba(136, 220, 250, 0.85); }
.dim-gray { background-color: #624E5A; }
.dark-slate-gray { background-color: #141318; }
